import React, { useState } from "react";
import * as footerStyles from "./footer.module.scss";
import { Link } from "gatsby";
import axios from "axios";

const Footer = () => {
  const [emailInput, setEmailInput] = useState("");
  const [formMessage, setFormMessage] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    setFormMessage("Thank you! for your interest");
    axios
      .post(
        "https://sheet.best/api/sheets/89d42962-4637-48a1-b16e-d4d53c80edc3",
        {
          email: emailInput,
          name: "",
          phone: "",
          company: "",
          message: "",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setFormMessage("You've successfully subscribed to our newsletter.");
        }
      });
  };

  return (
    <footer className={`es-section__blue  ${footerStyles.footerStylesWrapper}`}>
      <div>
        <div className={footerStyles.footerStylesHead}>
          <svg
            width="100"
            height="51"
            viewBox="0 0 100 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.6785 7.46947C75.9099 42.3694 38.4175 56.5582 3.59528 36.9103C2.43603 36.2561 0.965988 36.6651 0.311743 37.8101C-0.342503 38.9754 0.0664445 40.4474 1.22569 41.1017C38.5504 62.1807 78.8049 47.1536 98.9229 9.75932C99.5526 8.59395 99.1131 7.12169 97.9416 6.48789C96.7701 5.87454 95.3082 6.3041 94.6785 7.46947Z"
              fill="#CCE0F5"
            />
            <path
              d="M32.8988 21.943C36.7758 21.943 39.9187 18.8002 39.9187 14.9232C39.9187 11.0462 36.7758 7.90332 32.8988 7.90332C29.0218 7.90332 25.8789 11.0462 25.8789 14.9232C25.8789 18.8002 29.0218 21.943 32.8988 21.943Z"
              fill="#CCE0F5"
            />
            <path
              d="M61.9608 14.4753C65.762 14.4753 68.8436 11.3938 68.8436 7.59262C68.8436 3.79141 65.762 0.709961 61.9608 0.709961C58.1596 0.709961 55.0781 3.79141 55.0781 7.59262C55.0781 11.3938 58.1596 14.4753 61.9608 14.4753Z"
              fill="#CCE0F5"
            />
          </svg>

          <h4>Based in Lahore. Working Worldwide.</h4>
        </div>
        <div className={footerStyles.footerStylesSocials}>
          <a href="https://www.instagram.com/endshiftltd">
            intagram
            <span>
              <svg
                width="421"
                height="13"
                viewBox="0 0 421 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M80.2024 3.77562L313.898 12.2018C313.898 12.2018 316.252 12.3163 319.548 12.4767C319.572 11.9871 319.584 11.7423 319.608 11.2526C353.099 11.6552 386.603 11.813 420.106 11.9708C419.671 11.2135 419.707 10.479 420.225 9.52266C412.22 9.13317 404.698 8.52177 397.152 8.40001C295.337 5.65455 193.05 2.88618 91.1992 0.875169C61.0158 0.388121 31.2079 1.88249 0.976851 2.37471C0.50598 2.3518 0.011226 2.81855 -0.000686055 3.06336L80.2024 3.77562Z"
                  fill="#ABADAB"
                />
              </svg>
            </span>
          </a>
          <a href="https://facebook.com/endshiftltd">
            facebook
            <span>
              <svg
                width="421"
                height="13"
                viewBox="0 0 421 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M80.2024 3.77562L313.898 12.2018C313.898 12.2018 316.252 12.3163 319.548 12.4767C319.572 11.9871 319.584 11.7423 319.608 11.2526C353.099 11.6552 386.603 11.813 420.106 11.9708C419.671 11.2135 419.707 10.479 420.225 9.52266C412.22 9.13317 404.698 8.52177 397.152 8.40001C295.337 5.65455 193.05 2.88618 91.1992 0.875169C61.0158 0.388121 31.2079 1.88249 0.976851 2.37471C0.50598 2.3518 0.011226 2.81855 -0.000686055 3.06336L80.2024 3.77562Z"
                  fill="#ABADAB"
                />
              </svg>
            </span>
          </a>
          <a href="https://twitter.com/endshiftltd">
            twitter
            <span>
              <svg
                width="421"
                height="13"
                viewBox="0 0 421 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M80.2024 3.77562L313.898 12.2018C313.898 12.2018 316.252 12.3163 319.548 12.4767C319.572 11.9871 319.584 11.7423 319.608 11.2526C353.099 11.6552 386.603 11.813 420.106 11.9708C419.671 11.2135 419.707 10.479 420.225 9.52266C412.22 9.13317 404.698 8.52177 397.152 8.40001C295.337 5.65455 193.05 2.88618 91.1992 0.875169C61.0158 0.388121 31.2079 1.88249 0.976851 2.37471C0.50598 2.3518 0.011226 2.81855 -0.000686055 3.06336L80.2024 3.77562Z"
                  fill="#ABADAB"
                />
              </svg>
            </span>
          </a>
          <a href="https://www.linkedin.com/company/endshiftltd/">
            linkedin
            <span>
              <svg
                width="421"
                height="13"
                viewBox="0 0 421 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M80.2024 3.77562L313.898 12.2018C313.898 12.2018 316.252 12.3163 319.548 12.4767C319.572 11.9871 319.584 11.7423 319.608 11.2526C353.099 11.6552 386.603 11.813 420.106 11.9708C419.671 11.2135 419.707 10.479 420.225 9.52266C412.22 9.13317 404.698 8.52177 397.152 8.40001C295.337 5.65455 193.05 2.88618 91.1992 0.875169C61.0158 0.388121 31.2079 1.88249 0.976851 2.37471C0.50598 2.3518 0.011226 2.81855 -0.000686055 3.06336L80.2024 3.77562Z"
                  fill="#ABADAB"
                />
              </svg>
            </span>
          </a>
        </div>
        <div className={footerStyles.footerStylesAddressForm}>
          {/* <div className={footerStyles.footerStylesAddress}>
            <p>Popcorn Studio, 59-B Khayaban-e-Firdousi, Block B Phase 1 Johar Town, Lahore, Punjab 54000</p>
          </div> */}
          <div className={footerStyles.footerStylesContact}>
            {/* <p className="es-mb-12">
              <a href="tel:+923218824774">03218824774</a>
            </p> */}
            <p>
              <a href="mailto:hello@endshift.io">hello@endshift.io</a>
            </p>
          </div>
          <div className={footerStyles.footerStylesForm}>
            <form onSubmit={submitHandler}>
              <input
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                type="email"
                placeholder="Write your email address"
              />
              <button type="submit">
                <span>ok</span>
                <svg
                  width="86"
                  height="88"
                  viewBox="0 0 86 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M86 51.3444C86 74.9907 66.7482 88 43 88C19.2518 88 0 74.9907 0 51.3444C0 27.698 10.2229 1.96309e-06 33.9711 1.96309e-06C57.7193 1.96309e-06 86 27.698 86 51.3444Z"
                    fill="#CCE0F5"
                  />
                </svg>
              </button>
            </form>
            {formMessage && <small>{formMessage}</small>}
          </div>
        </div>
        <div className={footerStyles.footerStylesCopyright}>
          <p>Copyrights 2022 endshift (Pvt) Ltd. All rights reserved.</p>
          <div>
            <Link to="/terms-services">Terms</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
