import React from "react";
import "./ctaButton.scss";

const CTAButton = ({
  size,
  text,
  rounded,
  outlined,
  btnBG,
  spacingClasses,
  type,
  additionalClass,
  hasIcon,
  reverse,
  icon,
  isLink,
  href,
  disabled,
  btnTextColor,
}) => {
  const btnTextColorUpdated = btnTextColor?.split("--")[1].replace(")", "");

  return (
    <>
      {isLink ? (
        <a
          href={href}
          type={type}
          className={`es-btn es-btn-link btn text-color-${btnTextColorUpdated} ${
            reverse ? "flex-row-reverse" : ""
          } ${additionalClass} ${size} ${btnBG} ${spacingClasses} ${
            outlined ? "es-btn-outlined" : ""
          } ${rounded ? "es-btn-rounded" : ""}`}
        >
          {hasIcon && (
            <span className={reverse ? "es-ml-8" : "es-mr-8"}>
              {icon !== "" && icon}
            </span>
          )}

          {text}
        </a>
      ) : (
        <button
          type={type}
          disabled={disabled}
          className={`es-btn btn ${
            reverse ? "flex-row-reverse" : ""
          } ${additionalClass} ${size} ${btnBG} ${spacingClasses} ${
            outlined ? "es-btn-outlined" : ""
          } ${rounded ? "es-btn-rounded" : ""}`}
        >
          {hasIcon && (
            <span className={reverse ? "es-ml-8" : "es-mr-8"}>
              {icon !== "" && icon}
            </span>
          )}

          {text}
        </button>
      )}
    </>
  );
};

export default CTAButton;
