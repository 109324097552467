import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import CTAButton from "../CTAButton/CTAButton";
import * as headerStyles from "./header.module.scss";
import { HeaderNavListData } from "../../../data";

import { gsap } from "gsap";
import HeaderBlobs from "../HeaderBlobs/HeaderBlobs";
import HeaderUnderline from "../HeaderUnderline/HeaderUnderline";

const Header = ({ dataColor }) => {
  const [menuBtnToggle, setMenuBtnToggle] = useState(false);
  const [isMenuBtnDisabled, setIsMenuBtnDisabled] = useState(true);
  const [headerNavData, setHeaderNavData] = useState(() =>
    HeaderNavListData.map((n) => ({ ...n, isOpen: false }))
  );

  const [isHeader, setIsHeader] = useState(true);
  var lastScrollTop = useRef();
  useEffect(() => {
    lastScrollTop.current = document.documentElement.scrollTop;
    document.addEventListener("scroll", handleHeader);
    return () => {
      document.removeEventListener("scroll", handleHeader);
    };
  }, []);

  const handleHeader = (e) => {
    const st = document.documentElement.scrollTop;
    if (st > lastScrollTop.current) {
      if (st > 800) {
        setIsHeader(false);
      }
    } else {
      setIsHeader(true);
    }

    lastScrollTop.current = st;
  };

  const headerOpenTimeline = useRef();

  useEffect(() => {
    if (menuBtnToggle === true) {
      gsap.fromTo(
        ".header-links .initial-animation-link,.header-links .support-text",
        {
          y: 100,
          opacity: 0,
        },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          stagger: 0.1,
          ease: "expo.out",
          delay: 1,
        }
      );

      headerOpenTimeline.current = gsap
        .timeline()
        .to(".menu-btn", {
          disabled: () => {
            setIsMenuBtnDisabled(true);

            return true;
          },
        })
        .to(".header-links", { duration: 0.4, y: 0, ease: "back" })
        .fromTo(
          ".header-links",
          {
            duration: 1,
            clipPath: "circle(10% at 50% 50%)",
            ease: "back",
            delay: 1,
          },
          {
            duration: 1,
            clipPath: "circle(100%)",
            ease: "back",
          }
        )
        // .fromTo(
        //   ".es-logo, .es-hire-us-btn",
        //   { filter: "invert(0)" },
        //   { duration: 0.4, filter: "invert(1)" },
        //   "-=1"
        // )
        .to(".menu-btn", {
          disabled: () => {
            setIsMenuBtnDisabled(false);

            return false;
          },
        });
    } else {
      closeHeaderTween();
    }
  }, [menuBtnToggle]);

  const closeHeaderTween = () => {
    gsap.to(
      ".header-links .initial-animation-link,.header-links .support-text",
      {
        duration: 0.5,
        y: -100,
        opacity: 0,
        stagger: 0.05,
        ease: "expo.out",
      }
    );
    gsap
      .timeline()
      .fromTo(
        ".header-links",
        {
          duration: 0.5,
          clipPath: "circle(100% at 50% 50%)",
          ease: "back",
        },
        {
          delay: 1,
          duration: 0.5,
          clipPath: "circle(10% at 50% 50%)",
          ease: "back",
        }
      )
      .to(".header-links", { duration: 0.7, y: "-150%", ease: "back" })
      // .to(".es-logo, .es-hire-us-btn", { filter: "invert(0)" }, "-=2")
      .to(".menu-btn", {
        disabled: () => {
          setIsMenuBtnDisabled(false);

          return false;
        },
      });
  };

  const menuHandler = (index) => {
    document.querySelectorAll(".es-nav-item-title").forEach((el, index) => {
      el.style.display = "none";
    });

    let copyHeaderNavData = [...headerNavData];
    copyHeaderNavData = copyHeaderNavData.map((item) => {
      return { ...item, isOpen: false };
    });
    copyHeaderNavData[index] = { ...copyHeaderNavData[index], isOpen: true };
    setHeaderNavData(copyHeaderNavData);
  };

  const menuBackHandler = () => {
    document.querySelectorAll(".es-nav-item-title").forEach((el, index) => {
      el.style.display = "block";
    });

    let copyHeaderNavData = [...headerNavData];
    copyHeaderNavData = copyHeaderNavData.map((item) => {
      return { ...item, isOpen: false };
    });
    setHeaderNavData(copyHeaderNavData);
  };

  return (
    <header
      className={`header-global-wrapper ${headerStyles.headerStylesWrapper} ${
        isHeader ? "header-visible" : ""
      } ${menuBtnToggle ? "navigation-visible" : ""} `}
    >
      <div className={headerStyles.headerStylesNavigation}>
        <div className={`es-logo ${headerStyles.headerStylesLogo}`}>
          <a href="/" aria-label="Click to visit the homepage">
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 2.24455V61.7554C0 62.9951 1.00494 64 2.24455 64H61.7554C62.9951 64 64 62.9951 64 61.7554V2.24455C64 1.00494 62.9951 0 61.7554 0H2.24455C1.00494 0 0 1.00494 0 2.24455ZM52.3985 34.5501L52.3509 34.9486H35.4307V27.1345H42.6186L32.0058 17.6799L21.3814 27.1345H28.5449V36.3602C28.5449 39.375 31.0055 41.8356 34.0319 41.8356H50.0831L49.6846 42.5234C45.982 48.7215 39.2042 52.5809 32.0047 52.5809C19.8699 52.5809 10.1702 42.054 11.5492 29.673C12.5983 20.2509 20.2068 12.632 29.6265 11.5678C42.0215 10.1667 52.5658 19.8873 52.5658 32.0186C52.5681 32.826 52.5077 33.6823 52.3985 34.5501Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <div className={headerStyles.headerStylesNavBtn}>
          <button
            aria-label="menu"
            className={`menu-btn 
              ${menuBtnToggle ? headerStyles.headerStylesMenuBtnOpened : ""}
            `}
            disabled={isMenuBtnDisabled}
            onClick={() => {
              setMenuBtnToggle(!menuBtnToggle);
              menuBackHandler();
            }}
          >
            <span></span>
            <span></span>
          </button>
        </div>
        <div>
          <CTAButton
            type="button"
            size="btn-lg"
            text="Hire us"
            rounded={true}
            outlined={false}
            btnBG="es-btn-white"
            spacingClasses=""
            additionalClass="es-hire-us-btn"
            hasIcon={false}
            icon=""
            reverse={false}
            isLink={true}
            isShadow={false}
            href="/contact-us"
          />
        </div>
      </div>
      <div className={`header-links ${headerStyles.headerStylesNavWrapper}`}>
        <span className={`header-blobs ${headerStyles.headerStylesBlobRed}`}>
          <HeaderBlobs />
        </span>

        <div className={headerStyles.headerStylesNavList}>
          {headerNavData.map((item, index) => (
            <div
              className={`${
                item.isOpen
                  ? "es-nav-item-wrapper__opened"
                  : "es-nav-item-wrapper__closed"
              } ${headerStyles.innerNavItemListWrapper}`}
              key={index}
              data-open={item.isOpen}
            >
              <div
                className={`initial-animation-link ${headerStyles.innerNavItemListItem}`}
              >
                {item.innerLinks ? (
                  <div
                    className={`es-nav-item-title ${headerStyles.innerNavItemTitle}`}
                    onClick={() => {
                      item.isInnerLinks && menuHandler(index);
                    }}
                  >
                    {item.title}
                  </div>
                ) : (
                  <Link
                    className={`es-nav-item-title es-mb-0 ${headerStyles.innerNavItemTitle}`}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                )}

                <span className={headerStyles.innerNavItemUnderline}>
                  <HeaderUnderline />
                </span>
                {item.isInnerLinks && (
                  <span className={headerStyles.innerNavItemArrow}>
                    <svg
                      width="41"
                      height="35"
                      viewBox="0 0 41 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.5396 14.8346C28.6815 15.2404 28.0041 15.5666 27.8591 15.6442C20.9564 19.3403 14.1451 23.3508 7.64361 27.7173C5.82209 28.9407 5.33543 31.4133 6.55706 33.2375C7.77869 35.0617 10.2478 35.5491 12.0693 34.3257C18.3503 30.1064 24.9352 26.2333 31.6055 22.6605C32.114 22.388 36.633 20.5717 38.5181 19.2747C40.2244 18.099 40.8343 16.623 40.9594 15.7775C41.158 14.4348 40.6773 12.334 38.4049 10.6292C35.4392 8.40321 27.5155 5.51683 26.2005 5.04138C23.7811 4.17008 21.2425 3.43202 18.7734 2.7477C14.0895 1.45068 9.32211 0.7047 4.51902 0.0382879C2.34591 -0.264084 0.339694 1.25766 0.0377626 3.43395C-0.262182 5.60825 1.25747 7.61943 3.42859 7.91981C7.88406 8.53848 12.3097 9.21286 16.6539 10.4164C18.9323 11.049 21.2783 11.7254 23.511 12.529C24.1804 12.7717 26.9454 13.7146 29.5396 14.8346Z"
                        fill="url(#paint0_linear_1050_494)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1050_494"
                          x1="41"
                          y1="1.20664e-06"
                          x2="27.3569"
                          y2="41.9464"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0.122917" stopColor="#0066CC" />
                          <stop offset="0.496197" stopColor="#EB3300" />
                          <stop offset="0.854529" stopColor="#EB8D00" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                )}
                {item.isOpen && (
                  <>
                    <button
                      onClick={() => menuBackHandler()}
                      className={`es-menu-back-btn ${headerStyles.backBtn}`}
                    >
                      <span>
                        <svg
                          width="56"
                          height="56"
                          viewBox="0 0 56 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M54.3502 30.8115C40.1187 17.6466 20.8731 21.2097 5.56147 30.8494C5.29817 31.0144 4.94958 30.9358 4.7845 30.6725C4.61943 30.4093 4.69802 30.0607 4.96132 29.8956C20.7012 19.9851 40.4857 16.4511 55.1158 29.9851C55.3441 30.1955 55.3577 30.5518 55.1462 30.7811C54.9358 31.0094 54.5795 31.023 54.3502 30.8115Z"
                            fill="white"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.5471 30.1913C6.53599 30.144 7.77637 30.0709 7.91736 30.0667C11.7384 29.9531 15.3724 30.4384 19.0611 31.4715C19.3606 31.555 19.5357 31.8669 19.4522 32.1664C19.3687 32.4659 19.0568 32.6409 18.7573 32.5575C15.1806 31.5545 11.6565 31.0829 7.94997 31.1935C7.73393 31.1998 4.94568 31.3658 4.37937 31.3571C4.14707 31.3545 4.01641 31.2809 3.98723 31.2606C3.82821 31.1588 3.76743 31.0269 3.74073 30.9169C3.71153 30.7924 3.7134 30.5752 3.8678 30.3409C4.03036 30.0964 4.48994 29.6741 4.59734 29.5582C6.213 27.8041 8.16905 25.9872 9.63372 23.942C11.0307 21.9917 11.9818 19.8312 11.6644 17.2968C11.6255 16.9883 11.8456 16.7064 12.1541 16.6675C12.4626 16.6285 12.7445 16.8487 12.7835 17.1572C13.1378 19.9907 12.1116 22.4184 10.5507 24.5979C9.09439 26.6309 7.16791 28.4458 5.5471 30.1913Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <span>Back</span>
                    </button>

                    <ul>
                      {item.innerLinks.map((innerItem, innerIndex) => (
                        <li key={innerIndex} className="initial-animation-link">
                          <Link
                            className={headerStyles.innerNavItemLink}
                            to={innerItem.link}
                          >
                            {innerItem.name}
                            <span
                              className={headerStyles.innerNavItemUnderline}
                            >
                              <HeaderUnderline />
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={headerStyles.headerStylesSupport}>
          <span className="support-text">Support</span>
          <div>
            <Link className="initial-animation-link" to="/contact-us">
              Contact
            </Link>
          </div>
          {/* <div>
            <Link className="initial-animation-link" to="/">
              Blog
            </Link>
          </div> */}
          <div>
            <Link className="initial-animation-link" to="/work-with-us">
              work with us
            </Link>
          </div>
        </div>
        <div className={headerStyles.headerStylesSocials}>
          <div className={headerStyles.headerStylesSocialsCol}>
            {/* <div>
              <Link className="initial-animation-link" to="/cookies-policy">
                Cookies Policy
              </Link>
            </div> */}
            <div>
              <Link className="initial-animation-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link className="initial-animation-link" to="/terms-services">
                Terms
              </Link>
            </div>
          </div>
          <div className={headerStyles.headerStylesSocialsCol}>
            <div>
              <a
                className="initial-animation-link"
                href="https://facebook.com/endshiftltd"
              >
                Facebook
              </a>
            </div>
            <div>
              <a
                className="initial-animation-link"
                href="https://www.instagram.com/endshiftltd"
              >
                Instagram
              </a>
            </div>
            <div>
              <a
                className="initial-animation-link"
                href="https://twitter.com/endshiftltd"
              >
                Twitter
              </a>
            </div>
            <div>
              <a
                className="initial-animation-link"
                href="https://www.linkedin.com/company/endshiftltd/"
              >
                Linkedin
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
