import React from "react";

const HeaderBlobs = () => {
  return (
    <>
      <svg
        width="1098"
        height="661"
        viewBox="0 0 1098 661"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_982_4)">
          <mask
            id="mask0_982_4"
            maskUnits="userSpaceOnUse"
            x="150"
            y="150"
            width="798"
            height="798"
          >
            <circle
              r="399"
              transform="matrix(-1 0 0 1 549 549)"
              fill="#C4C4C4"
            />
          </mask>
          <g mask="url(#mask0_982_4)">
            <path
              d="M621.92 -213.249L536.397 -127.726C467.047 -58.3753 466.975 53.9386 536.145 123.109C594.364 181.328 688.781 181.268 747.076 122.973C805.37 64.6783 899.787 64.6176 958.007 122.837L966.584 131.415C1024.8 189.635 1024.74 284.051 966.449 342.346C908.154 400.641 908.093 495.057 966.313 553.277L974.891 561.855C1044.06 631.025 1156.37 630.953 1225.63 561.694L1322.67 464.658"
              stroke="#EB3300"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M551.2 -142.528L465.677 -57.0052C396.417 12.254 396.345 124.568 465.515 193.738C523.735 251.958 618.152 251.897 676.446 193.602C734.741 135.308 829.158 135.247 887.377 193.467L895.955 202.044C954.175 260.264 954.114 354.681 895.819 412.975C837.525 471.27 837.464 565.687 895.684 623.906L904.262 632.484C973.432 701.654 1085.75 701.582 1155 632.323L1251.95 535.378"
              stroke="#EA4517"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M480.57 -71.8986L395.047 13.6245C325.788 82.8837 325.716 195.198 394.886 264.368C453.106 322.587 547.522 322.527 605.817 264.232C664.112 205.937 758.528 205.877 816.748 264.096L825.326 272.674C883.545 330.894 883.485 425.311 825.19 483.605C766.896 541.9 766.835 636.316 825.054 694.536L833.632 703.114C902.802 772.284 1015.12 772.212 1084.38 702.953L1181.23 606.1"
              stroke="#EA572E"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M409.848 -1.17754L324.325 84.3456C255.066 153.605 254.994 265.919 324.164 335.089C382.383 393.308 476.8 393.248 535.095 334.953C593.389 276.658 687.806 276.598 746.026 334.817L754.604 343.395C812.823 401.615 812.763 496.032 754.468 554.326C696.173 612.621 696.113 707.038 754.332 765.257L762.91 773.835C832.08 843.005 944.394 842.933 1013.65 773.674L1110.6 676.729"
              stroke="#E96945"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M339.127 69.5435L253.604 155.067C184.345 224.326 184.273 336.64 253.443 405.81C311.663 464.029 406.079 463.969 464.374 405.674C522.669 347.379 617.085 347.319 675.305 405.538L683.883 414.116C742.103 472.336 742.042 566.753 683.747 625.047C625.453 683.342 625.392 777.759 683.611 835.978L692.189 844.556C761.359 913.726 873.673 913.654 942.932 844.395L1039.88 747.45"
              stroke="#E97B5C"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M268.407 140.265L182.884 225.788C113.624 295.047 113.552 407.361 182.722 476.531C240.942 534.75 335.359 534.69 393.653 476.395C451.948 418.1 546.365 418.04 604.584 476.259L613.162 484.837C671.382 543.057 671.321 637.474 613.026 695.768C554.732 754.063 554.671 848.48 612.891 906.699L621.469 915.277C690.639 984.447 802.953 984.375 872.212 915.116L969.156 818.171"
              stroke="#66A3E0"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M197.777 210.894L112.254 296.417C42.9952 365.676 42.9229 477.99 112.093 547.16C170.313 605.38 264.729 605.319 323.024 547.025C381.319 488.73 475.735 488.669 533.955 546.889L542.533 555.467C600.753 613.687 600.692 708.103 542.397 766.398C484.103 824.692 484.042 919.109 542.261 977.329L550.839 985.907C620.009 1055.08 732.323 1055 801.582 985.745L898.436 888.892"
              stroke="#4D94DB"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M127.055 281.615L41.532 367.138C-27.7271 436.398 -27.7994 548.712 41.3707 617.882C99.5904 676.101 194.007 676.04 252.302 617.746C310.596 559.451 405.013 559.391 463.233 617.61L471.811 626.188C530.03 684.408 529.97 778.824 471.675 837.119C413.38 895.414 413.32 989.83 471.539 1048.05L480.117 1056.63C549.287 1125.8 661.601 1125.73 730.86 1056.47L827.805 959.522"
              stroke="#3385D6"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M56.3345 352.336L-29.1886 437.859C-98.4477 507.119 -98.52 619.433 -29.35 688.603C28.8697 746.822 123.286 746.762 181.581 688.467C239.876 630.172 334.292 630.112 392.512 688.331L401.09 696.909C459.31 755.129 459.249 849.545 400.954 907.84C342.66 966.135 342.599 1060.55 400.819 1118.77L409.396 1127.35C478.566 1196.52 590.88 1196.45 660.139 1127.19L757.084 1030.24"
              stroke="#0066CC"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
            <path
              d="M-14.3862 423.058L-99.9094 508.581C-169.169 577.84 -169.241 690.154 -100.071 759.324C-41.8511 817.544 52.5657 817.483 110.86 759.188C169.155 700.893 263.572 700.833 321.791 759.052L330.369 767.63C388.589 825.85 388.528 920.267 330.233 978.561C271.939 1036.86 271.878 1131.27 330.098 1189.49L338.676 1198.07C407.846 1267.24 520.16 1267.17 589.419 1197.91L686.363 1100.96"
              stroke="#1A75D1"
              strokeWidth="103"
              strokeMiterlimit="10"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f_982_4"
            x="0"
            y="0"
            width="1098"
            height="1098"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="75"
              result="effect1_foregroundBlur_982_4"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default HeaderBlobs;
