import React from "react";

const HeaderUnderline = () => {
  return (
    <>
      <svg
        width="380"
        height="8"
        viewBox="0 0 380 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M72.4738 6.16372L283.785 7.25335C283.785 7.25335 285.915 7.29398 288.896 7.35086C288.906 6.86073 288.911 6.61567 288.92 6.12555C319.183 5.47716 349.452 4.58372 379.72 3.69027C379.308 2.94696 379.322 2.21177 379.767 1.23965C372.526 1.10151 365.716 0.726439 358.897 0.84149C266.856 1.2919 174.39 1.73418 82.3354 2.91978C55.0579 3.37998 28.1689 5.80878 0.872735 7.24923C0.446817 7.2411 0.0114962 7.72312 0.00682091 7.96818L72.4738 6.16372Z"
          fill="url(#paint0_linear_988_404)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_988_404"
            x1="70.5895"
            y1="-1.54289"
            x2="77.1806"
            y2="50.5941"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.122917" stopColor="#0066CC" />
            <stop offset="0.496197" stopColor="#EB3300" />
            <stop offset="0.854529" stopColor="#EB8D00" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default HeaderUnderline;
