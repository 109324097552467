// extracted by mini-css-extract-plugin
export var headerStylesWrapper = "header-module--headerStylesWrapper--ClA8c";
export var headerStylesLogo = "header-module--headerStylesLogo--6RkWN";
export var headerStylesNavigation = "header-module--headerStylesNavigation--m6XyP";
export var headerStylesBlobRed = "header-module--headerStylesBlobRed--2F5QF";
export var headerStylesNavBtn = "header-module--headerStylesNavBtn--tNY9u";
export var headerStylesMenuBtnOpened = "header-module--headerStylesMenuBtnOpened--QZoo5";
export var headerStylesNavWrapper = "header-module--headerStylesNavWrapper--qHY5x";
export var headerStylesNavList = "header-module--headerStylesNavList--SQp5k";
export var innerNavItemListItem = "header-module--innerNavItemListItem--wTaS6";
export var innerNavItemUnderline = "header-module--innerNavItemUnderline--I9P4V";
export var innerNavItemTitle = "header-module--innerNavItemTitle--46gKC";
export var innerNavItemArrow = "header-module--innerNavItemArrow--etlvz";
export var innerNavItemLink = "header-module--innerNavItemLink--FxTY8";
export var headerStylesSupport = "header-module--headerStylesSupport--QDCib";
export var headerStylesSocials = "header-module--headerStylesSocials--6NSF2";
export var headerStylesSocialsCol = "header-module--headerStylesSocialsCol--smMyx";
export var innerNavItemListWrapper = "header-module--innerNavItemListWrapper--78Nqo";
export var backBtn = "header-module--backBtn--ixNWs";